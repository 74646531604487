<template>
  <v-container fluid>
    <v-row
      justify="space-between"
      align="center"
      no-gutters
      class="mb-4"
    >
      <v-col cols="4">
        <v-card
          class="pa-2"
          flat
        >
          <v-text-field
            v-model="search.string"
            prepend-inner-icon="mdi-magnify"
            outlined
            dense
            hide-details
            clearable
            height="40"
            label="Wyszukaj"
            @input="onSearchInput"
          />
        </v-card>
      </v-col>
      <v-col cols="auto">
        <v-card
          flat
          class="pa-2 d-flex align-center justify-center"
          style="max-width: min-content"
        >
          <v-btn
            :elevation="0"
            large
            height="40"
            color="primary lighten-1"
            @click="isSelectSpeditorDialogOpen = true"
          >
            <v-icon left>
              {{ $t('sets.newSetIcon') }}
            </v-icon>
            <span>{{ $t('sets.newSet') }} </span>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table
      :headers="TABLE_HEADERS"
      :items="configurationSets"
      :items-per-page="-1"
      :loading="loading.tableData"
      disable-sort
      class="my-4"
      hide-default-footer
      no-data-text="Brak danych"
      loading-text="Ładowanie danych"
    >
      <template v-slot:no-data>
        <div
          class="d-flex align-center justify-center"
          style="height: 200px"
        >
          <div>
            <strong class="d-block my-2"> {{ $t('courierSet.table.noItems') }}</strong>
            <span class="d-block my-2">
              {{ $tc('courierSet.table.new', 0) }}
              <strong>"+ {{ $tc('courierSet.table.new', 1) }}"</strong>,
              {{ $tc('courierSet.table.new', 2) }}.</span
            >
          </div>
        </div>
      </template>
      <template v-slot:item.configurationName="{ item }">
        <span class="link">
          <a
            target="_blank"
            @click="onRowAction('edit', item)"
          >
            {{ item.configurationName }}
          </a>
        </span>
      </template>
      <template v-slot:item.integration="{ item }">
        <span v-if="item.integration.toUpperCase() !== 'CUSTOMSHIPMENT'">
          {{ item.integration }}
        </span>
        <span v-else> {{ $t('courierSet.customShipment') }} </span>
      </template>
      <template v-slot:item.courier="{ item }">
        <span>
          {{ getShipmentCourierCode(item.courierCode) }}
        </span>
      </template>
      <template v-slot:item.externalLP="{ item }">
        <v-chip
          v-if="item.labelSource === 'ExternalSystem'"
          label
          x-small
          color="priority_yellow"
        >
          <span class="font-weight-bold">{{ $t('courierSet.externalLP') }}</span>
        </v-chip>
      </template>
      <template v-slot:item.events="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon color="black"> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>
          <v-card min-width="170">
            <v-list dense>
              <v-list-item
                v-for="(action, index) in ROW_ACTIONS"
                :key="index"
                link
                @click="onRowAction(action.value, item)"
              >
                <div class="d-flex align-center">
                  <v-list-item-icon
                    class="mr-2"
                    style="position: relative"
                  >
                    <v-icon :color="action.icon.color || 'black'">
                      {{ action.icon.name }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <span :class="`text--${action.textColor || 'black'}`">
                      {{ action.text }}
                    </span>
                  </v-list-item-title>
                </div>
                <v-divider
                  v-if="action.divider"
                  class="divider"
                />
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog
      v-model="isSelectSpeditorDialogOpen"
      max-width="1044px"
      @click:outside="isSelectSpeditorDialogOpen = false"
    >
      <SelectSpeditor
        :couriers="configuredCouriers"
        @@close="isSelectSpeditorDialogOpen = false"
      />
    </v-dialog>

    <DeleteSetDialog
      :is-open="isDeleteSetDialogOpen"
      @@close="isDeleteSetDialogOpen = false"
      @@delete="deleteSet(selectedConfigurationSet.id)"
    />
  </v-container>
</template>

<script>
import DeleteSetDialog from '@/components/dialogs/DeleteSetDialog.vue';
import policyServerApiService from '@/services/policyServer/policy-server-api';
import { roles } from '@/constants/Roles';
import { mapGetters, mapActions } from 'vuex';
import configurationApiService from '@/services/spedition/configuration-api';
import SelectSpeditor from '@/components/dialogs/SelectSpeditor.vue';
import { speditors } from '@/constants/Speditors';
import { TABLE_HEADERS, ROW_ACTIONS } from '@/constants/ConfigurationSets';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';

export default {
  name: 'OrganizationConfigurationSets',
  components: {
    SelectSpeditor,
    DeleteSetDialog,
  },

  data: () => ({
    organization: { id: '' },
    configurationSets: [],
    hoveredConfigurationSet: null,
    selectedConfigurationSet: null,
    selectedSpeditor: null,
    roles,
    isSelectSpeditorDialogOpen: false,
    speditors,
    isDeleteSetDialogOpen: false,

    loading: {
      tableData: false,
    },

    search: {
      string: '',
      timeout: undefined,
    },

    TABLE_HEADERS,
    ROW_ACTIONS,
  }),

  computed: {
    ...mapGetters(['oidcStore/oidcUser']),

    userRole() {
      if (this['oidcStore/oidcUser']) {
        const user = this['oidcStore/oidcUser'];
        return user.roles?.find((x) => roles.ALL.some((y) => y === x));
      }
      return null;
    },

    getUserOrganizationId() {
      if (this['oidcStore/oidcUser']) {
        const user = this['oidcStore/oidcUser'];
        return user.tenantId;
      }
      return null;
    },

    configuredCouriers() {
      const couriers = [];
      if (this.organization) {
        const sets = this.configurationSets?.filter((set) => set.tenantId === this.organization.id);
        if (sets.length !== 0) {
          Object.keys(sets).forEach((s) => couriers.push(sets[s].integration));
          return [...new Set(couriers)];
        }
      }
      return [];
    },
  },

  watch: {
    configuredCouriers(newValue) {
      if (newValue.length) {
        [this.selectedSpeditor] = newValue;
      } else {
        this.selectedSpeditor = null;
      }
    },
  },

  async created() {
    await this.getOrganization();
    await this.getConfigurationSets();
  },

  mounted() {
    this.clearValues();
  },

  methods: {
    ...mapActions('spedition', ['setOrganization', 'setConfigSet', 'clearValues']),
    getShipmentCourierCode(shipmentCourierCode) {
      const speditor = SHIPMENT_METHOD_CODES.find(
        ({ id }) => id.toLowerCase() === shipmentCourierCode?.toLowerCase(),
      );
      if (!speditor) return shipmentCourierCode;
      return speditor.name;
    },
    onSearchInput() {
      clearTimeout(this.search.timeout);
      this.search.timeout = setTimeout(() => {
        this.getConfigurationSets();
      }, 300);
    },

    // action: 'delete' | 'copy' | 'edit'
    async onRowAction(action, row) {
      this.selectedConfigurationSet = row;
      if (action === 'delete') this.openDeleteDialog(row);
      if (action === 'copy') this.onCopy(this.selectedConfigurationSet, row);
      if (action === 'edit') {
        this.onEdit(this.selectedConfigurationSet);
      }
    },

    async onCopy(configurationSet, row) {
      try {
        const { data: configurationData } = await configurationApiService.getConfigurationSet(
          row.courier,
          configurationSet.id,
          null,
          this.getUserOrganizationId,
        );

        const copyDocBody = {
          ...configurationData,
          configurationName: `${configurationData.configurationName}(1)`,
        };
        delete copyDocBody.id;
        copyDocBody.password = '';

        configurationApiService.traverse(copyDocBody, (v, k) => {
          if (typeof k === 'object' && k !== null) {
            k.clientEntityState = 4;
            delete k.id;
          }
        });

        await configurationApiService.createConfigurationSet(
          row.courier,
          copyDocBody,
          this.getUserOrganizationId,
        );
        await this.getConfigurationSets();
      } catch (err) {
        console.warn(err);
      }
    },

    async onEdit(configurationSet) {
      const { id, tenantId, courier, clientId } = configurationSet;
      const { data: confSet } = await configurationApiService.getConfigurationSet(
        courier,
        id,
        clientId,
        tenantId,
      );
      await this.setConfigSet({ response: confSet });
      await this.$router.push({
        name: `user-configuration-set-${courier.toLowerCase()}-edit`,
        params: {
          speditor: courier,
          confSetId: id,
          usrId: tenantId,
        },
      });
    },

    openDeleteDialog() {
      this.isDeleteSetDialogOpen = true;
    },

    showConfigurationSets(speditor) {
      this.selectedSpeditor = speditor;
    },

    getCourierConfigurationSets(org, sped) {
      const orgId = org.id;
      return this.configurationSets?.filter(
        (set) => set.tenantId === orgId && set.courier === sped,
      );
    },

    async getOrganization() {
      try {
        this.organization = await policyServerApiService.getOrganization(
          this.getUserOrganizationId,
        );
        this.setOrganization({ organization: this.organization });
      } catch (err) {
        const { response = '' } = err;
        const message = response?.data?.message || 'Błąd';
        this.$snotify.error(message);
        console.warn(err);
      }
    },

    async getConfigurationSets() {
      this.loading.tableData = true;
      try {
        const { data: responseData } = await configurationApiService.getAll({
          query: { searchString: this.search.string },
        });
        this.configurationSets = responseData.items.map((x) => ({
          ...x,
          servicesLoading: {},
        }));
      } catch (err) {
        const { response = '' } = err;
        const message = response?.data?.message || 'Błąd';
        this.$snotify.error(message);
        console.warn(err);
      }
      this.loading.tableData = false;
    },

    isServiceEnabled(serviceCode, org) {
      return org.services?.find((x) => x.serviceCode === serviceCode)?.isEnabled;
    },

    onSetHover(set) {
      this.hoveredConfigurationSet = set;
    },

    logo(speditor) {
      const spedLogo = this.speditors.find(
        (x) => x.code.toLowerCase() === speditor.toLowerCase(),
      )?.logo;
      const images = require.context('@/assets/images/logo/speditors/', false, /\.png$/);
      return spedLogo ? images(`./${spedLogo}`) : '';
    },

    async deleteSet(setId) {
      this.isDeleteSetDialogOpen = false;

      const { courier } = this.selectedConfigurationSet;
      const confSet = await configurationApiService.getConfigurationSet(
        courier,
        setId,
        this.usrId,
        this.organization.id,
      );

      await configurationApiService.deleteConfigurationSet(
        this.selectedConfigurationSet.courier,
        confSet.data,
        this.organization.id,
      );
      await this.getConfigurationSets();
      this.selectedConfigurationSet = null;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/utilities/variables';
@import '@/assets/styles/headers.scss';

.divider {
  position: absolute;
  bottom: 0;
  left: 5px;
  right: 5px;
}

.btn-new-configuration-set {
  background-color: $primary-lighten1 !important;
  color: white;
  overflow: auto;
  height: 48px;
}

.list-title {
  font-weight: bold;
  color: $text-lighten1;
  font-size: 1rem;
  padding: 10px 12px;
}

.number-label {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: $primary-lighten4;
  color: $primary-lighten1;
  border: solid $primary-lighten1 2px;
  width: 32px;
  height: 24px;
  font-size: 12px;
  margin: 4px;
  position: absolute;
  z-index: 1;
}

@mixin img {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  border: 2px solid;
  border-radius: 4px;
  border-color: $white;
  background-color: $white;
  margin-bottom: 8px;
}

@mixin linkMixin {
  text-decoration: none;
  color: $black;
  max-width: min-content;
  padding: 3px 5px;
  border-radius: 4px;
  cursor: pointer;
}

.link a {
  @include linkMixin();

  color: $primary-lighten1 !important;
  text-decoration: underline;

  &:hover {
    background-color: $primary-lighten4;
    color: $primary-lighten1;
    text-decoration: underline;
  }

  &-warning {
    @include linkMixin();
    color: $danger;

    &:hover {
      background-color: $danger-lighten3;
    }
  }
}
.speditior-logo {
  @include img;
  position: relative;
  filter: opacity(50%);
  border: 2px solid;
  border-color: $text-lighten1;

  &:hover {
    border: 2px solid;
    border-color: $primary-lighten1;
    filter: opacity(100%);
    box-shadow: 0px 1px 8px 0px #0000000d;
    box-shadow: 0px 3px 3px 0px #0000001f;
    box-shadow: 0px 3px 4px 0px #0000001a;
  }

  &-selected {
    @include img;
    border-color: $primary-lighten1;
    box-shadow: 0px 1px 8px 0px #0000000d;
    box-shadow: 0px 3px 3px 0px #0000001f;
    box-shadow: 0px 3px 4px 0px #0000001a;
  }
}

.users-speditors-label {
  font-size: 1rem;
  color: $text-lighten1;
  font-weight: bold;
}

.speditors-wrapper {
  height: 75vh;
  overflow: auto;
  padding: 8px;
  padding-top: 0px;
  direction: rtl;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
</style>
